import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
//import AboutProject from '../../../../../components/case-studies/AboutProject'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../../components/case-studies/FontShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import AboutProject from '../../../../../components/case-studies/AboutProject'

class Inspirativni extends Component {
  render() {
    const { data } = this.props

    return (
      <Layout showBackButton={true}>
        <div className={'inspirativni'}>
          <Landing
            title={'Branding Inspirativni.cz'}
            description={'Prípadová štúdia'}
            description2="Návrh loga a firemná identita pre obľúbený český portál, ktorý čitateľom denne poskytuje blogy s rôznymi tipmi a inšpiráciami."
            text={'Kvalitne spracovaný branding rozpráva príbeh značky. Okrem toho, že vystihuje jej zámer a filozofiu, zviditeľňuje ju na trhu a robí ju ľahšie rozpoznateľnou. Klientovi sa náš grafický tím postaral o všetko potrebné.'}
            className={'non-full-height'}
            img={data.landingImage}
            what_we_done={[
              { text: 'tvorba logotypu', link: '/nase-prace/grafika/logo-branding/inspirativnicz-branding' },
              { text: 'logomanuál' },
              { text: 'designmanuál' },
            ]}
            noShadow={true}
          />

          <AboutProject
            title={'Tvorba brandu ako sa patrí'}
            list={[
              {
                title: 'Uchopenie projektu',
                text: 'Web Inspirativni.cz pôsobí na internete už 9 rokov a svojim čitateľom prináša dennú inšpiráciu z oblasti módy, bývania a životného štýlu. Klient nás oslovil s požiadavkou na <b>vytvorenie sviežeho</b> <a class="blue-link" href="/branding">brandingu</a>, ktorý by webu vdýchol nový život. Po úvodnej konzultácii sme sa pustili do práce.',
              },
              {
                title: 'Návrh riešenia',
                text: 'Cieľom bola tvorba firemnej identity, ktorá bude príťažlivá pre návštevníkov a nezapadne do šedého priemeru. Do toho spadá grafický návrh <a class="blue-link" href="/tvorba-loga">loga</a>, brand book i výber farieb. S klientom sme si na stretnutiach odkomunikovali zadanie tak, aby projekt <b>splnil jeho očakávania</b> a bol s výsledkom spokojný.',
              },
              {
                title: 'Priebeh vývoja',
                text: 'Na stretnutiach sme odprezentovali niekoľko prvotných návrhov loga <b>a diskutovali sme o nich</b>. Klient si vybral jednu z verzií, ktorú sme ďalej rozpracovávali podľa jeho predstáv. Po niekoľkých kolách pripomienok sme dospeli k finálnej verzii, ku ktorej sme vytvorili kompletnú firemnú identitu.',
              },
              {
                title: 'Úspešný výsledok',
                text: 'Výsledkom bola jasne zadefinovaná vizuálna firemná identita, ktorá vystihuje zámer webu Inspirativni.cz. Profesionálny <b>branding rozpráva príbeh</b> a už na prvý pohľad padne návštevníkom do oka. Aj vďaka tomu si návštevníci web ľahko zapamätajú a to nepochybne <b>prispieva k jeho úspechu</b>.',
              }
            ]}
          />

          <ProjectShortDescription
            title="Tvorba brandu"
            description="Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom."

            text={'Po konzultáciách s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'} />

          <Reveal>
            <div className={'container p-0 mb-4'}>
              <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
            </div>
          </Reveal>

          <ColorShowcase colors={[
            { code: '#ed227c', tintColorCode: '#f14e96', nameColor: '#FFFFFF', name: 'Magentová' },
            { code: '#000000', tintColorCode: '#333333', nameColor: '#FFFFFF', name: 'Čierna' },
            { code: '#ffffff', tintColorCode: '#ffffff', nameColor: '#6B7B8A', name: 'Biela' },
          ]} />

          <FontShowcase
            fontName={'Ubuntu'}
            text={'Spôsob, akým je použitá typografia v projekte, hovorí niekedy viac ako samotné slová.<br><br>Typ písma Ubuntu bol špeciálne vytvorený, aby doplnil známy Ubuntu štýl. Má súčasný dizajn a obsahuje symboly typické práve pre značku Ubuntu, ktorá predstavuje precízny, dôveryhodný a voľný prístup.'}
            isFullWidth={false}
            fontExampleSvg={'/images/svg/fonts/ubuntu.svg'}
          />

          <Reveal>
            <div className={'p-0 inspirativny-background'}>
              <div className={'container p-0'}>
                <Img className={'mb-5'} alt={this.constructor.name} fluid={data.corporateIdentity.childImageSharp.fluid} />
              </div>
            </div>
          </Reveal>

          {/*<ClientReference
            text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
            clientName={'David Kopecký'}
            clientRole={'Majiteľ Allriskslovakia'}
            clientPhoto={data.clientPhoto}
          />*/}

          <div className="extern-page-link">
            <a rel="noreferrer" href="https://www.inspirativni.cz" target='_blank' className='link-secondary'>www.inspirativni.cz</a>
          </div>

          <Footer
            title={'OurServices.Process'}
            subtitle={'OurServices.ProcessSubTitle'}
            link={'/o-nas/ako-pracujeme'}
            leftTopTitle='project'
            nextProjectName={'Openmind'}
            nextProjectUrl={'/nase-prace/pripadove-studie/grafika/logo-branding/openmind'}

          />
        </div>
      </Layout>
    )
  }
}

export const query = graphql`{
    landingImage: file(relativePath: { eq: "case-studies/inspirativni_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/1_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/4_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/6_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/inspirativni_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    corporateIdentity: file(relativePath: { eq: "case-studies/inspirativni_ci.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default Inspirativni
